import styles from "./CardProductCenter.module.scss";
import RC1Image from "../RC1Image/RC1Image";
import Link from "next/link";
import Buttons from "../Buttons/Buttons";

export interface CardProductCenterProps {
	product: any;
	setMargin?: "0" | "1" | "1.5" | "2" | "2.5" | "3" | "4";
	buttonText?: string;
	bg?: "white" | "transparent";
}

const CardProductCenter = ({
	product,
	setMargin = "0",
	buttonText = "",
	bg = "white",
}: CardProductCenterProps): JSX.Element => {
	return (
		<>
			{product && (
				<div
					className={styles.cardCenter}
					style={{ margin: `${setMargin}em`, background: bg }}
				>
					<Link
						prefetch={false}
						href={
							product?.linkObject?.fields?.handle
								? `/products/${product?.linkObject?.fields?.handle.replaceAll(
										"us_retail-product-",
										""
								  )}`
								: "/"
						}
					>
						<a>
							<div className={styles.wrapImg}>
								{product?.image?.fields ? (
									<RC1Image
										draggable={false}
										src={
											product?.image?.fields?.file?.url
												? "https:" +
												  product?.image?.fields?.file
														?.url +
												  "?fm=webp"
												: "/no-image.jpeg"
										}
										layout="fill"
										objectFit="contain"
										alt={product?.altText || product?.title}
										loading="lazy"
									/>
								) : (
									<RC1Image
										draggable={false}
										src={
											product?.featuredMedia?.src
												? product?.featuredMedia?.src +
												  "?fm=webp"
												: "/no-image.jpeg"
										}
										layout="fill"
										objectFit="contain"
										alt={product?.altText || product?.title}
										loading="lazy"
									/>
								)}
							</div>
						</a>
					</Link>
					<div className={styles.cardCenterText}>
						<h2>{product?.title || product?.shortName}</h2>
					</div>
					<div className={styles.cardCenterPrice}></div>
					<div className={styles.cardCenterActions}>
						{product?.linkObject?.fields ? (
							<Buttons
								destination={
									product?.linkObject?.fields?.handle
										? `/products/${product?.linkObject?.fields?.handle.replaceAll(
												"us_retail-product-",
												""
										  )}`
										: "/"
								}
								text={buttonText ? buttonText : "Buy Now"}
								variant="fill"
							/>
						) : (
							<Buttons
								destination={
									product?.destinationUrl ||
									`/products/${product?.handle}` ||
									"/"
								}
								text={buttonText ? buttonText : "Buy Now"}
								variant="fill"
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default CardProductCenter;
