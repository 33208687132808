import styles from "./SlideCustomVideo.module.scss";
import { useMediaQuery } from "react-responsive";
import { SCREENS } from "helpers/responsive";
import { IVideoList } from "interfaces/index";
import Video from "../Video/Video";
import Slider from "react-slick";
import SlickArrows from "../SlickArrows/SlickArrows";
import { useEffect, useState } from "react";

interface SlideCustomProps {
	videoList: IVideoList[];
	showBylide?: "1" | "2" | "3";
	showHeader?: boolean;
	centerMode?: boolean;
	centerPadding?: string;
}

const SlideCustomVideo = ({
	videoList = [],
	showBylide = "3",
	showHeader = false,
	centerMode = false,
	centerPadding = "15%",
}: SlideCustomProps) => {
	const isMobile = useMediaQuery({ query: `(max-width: ${SCREENS.px1000})` });
	const [mounted, setMounted] = useState(false);

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: isMobile ? 1 : parseInt(showBylide),
		slidesToScroll: 1,
		// arrows: false,
		centerMode: centerMode,
		centerPadding: centerPadding,
		initialSlide: 2,
	};

	const handleSlideSize = (size: number) => {
		switch (size) {
			case 1:
				return "100%";
			case 3:
				return "60%";
		}
	};

	useEffect(() => {
		setMounted(true);
	}, []);

	if (!videoList) return null;

	return (
		<>
			{mounted && (
				<Slider
					nextArrow={
						<SlickArrows
							type="next"
							light={true}
							onClick={onclick}
							bottom={30}
						/>
					}
					prevArrow={
						<SlickArrows
							type="prev"
							light={true}
							onClick={onclick}
							bottom={30}
						/>
					}
					{...settings}
				>
					{videoList?.map((item: any, index: any) => (
						<div
							className={styles.slide}
							key={index}
							style={{
								minWidth: handleSlideSize(parseInt(showBylide)),
							}}
						>
							{showHeader && (
								<div className={styles.videoCardHeader}>
									<h2>{item?.fields?.label}</h2>
									{item?.fields?.description && (
										<div
											dangerouslySetInnerHTML={{
												__html: item?.fields
													?.description,
											}}
										></div>
									)}
								</div>
							)}
							<div className={styles.marginVideo}>
								<Video video={item} />
							</div>
						</div>
					))}
				</Slider>
			)}
		</>
	);
};

export default SlideCustomVideo;
