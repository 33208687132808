import styles from "./BarSection.module.scss";
import Buttons from "../Buttons/Buttons";
import { useRouter } from "next/router";

export interface BarSectionProps {
	cardLink: any;
}

const BarSection = ({ cardLink }: BarSectionProps): JSX.Element => {
	const router = useRouter();

	const handleGoTo = () => {
		router.push(
			`/products/${cardLink?.linkObject?.fields?.handle}`.replace(
				"us_retail-product-",
				""
			)
		);
	};

	if (!cardLink) return null;

	return (
		<div className="col-md-12">
			<div className={`row ${styles.barSection}`}>
				{cardLink?.linkObject ? (
					<>
						<div className={`col-md-2 ${styles.barSection0}`}>
							<figure>
								<div className={styles.wrapImg}>
									<img
										src={
											cardLink?.image?.fields.file?.url
												? cardLink?.image?.fields.file
														?.url + "?fm=webp"
												: null
										}
										alt={cardLink?.altText}
									/>
								</div>
							</figure>
						</div>
						<div className={`col-md-5 ${styles.section1}`}>
							<h2>{cardLink?.label}</h2>
						</div>
					</>
				) : (
					<>
						<div className={`col-md-7 ${styles.section1}`}>
							<h2>{cardLink?.label}</h2>
						</div>
					</>
				)}
				<div className={`col-md-5 ${styles.section2}`}>
					<p>{cardLink?.description}</p>
					<div className={styles.servingsPrice}>
						<span>30 Servings</span>
						<span>$71.98</span>
					</div>
					<Buttons
						callBack={() => handleGoTo()}
						text="Shop Now"
						variant="fill"
					/>
				</div>
			</div>
		</div>
	);
};

export default BarSection;
