/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from "react";
import styles from "./SlickArrows.module.scss";
import useMediaQuery from "@/hooks/useMediaQuery";
interface SlickArrowsProps {
	type: string;
	onClick: any;
	light: boolean;
	bottom: number | null;
}

const SlickPrevArrow = ({ type, light, onClick, bottom }: SlickArrowsProps) => {
	let arrowClassName =
		type === "next" ? styles.slickPrevArrow : styles.slickNextArrow;

	let circleClassName =
		type === "next" ? styles.circlePrevArrow : styles.circleNextArrow;

	const isMobile = useMediaQuery("(max-width: 640px");
	const isTablet = useMediaQuery("(min-width: 641px)");
	const isDesktop = useMediaQuery("(min-width: 991px)");

	const offsetDesktop = {
		bottom: bottom ? `${bottom}% ` : `50%`,
	};

	const offsetMobile = {
		bottom: bottom ? `${bottom}% ` : `50%`,
	};

	const offsetTablet = {
		bottom: bottom ? `${bottom}% ` : `50%`,
	};

	return (
		<button
			className={`
				${circleClassName}		
				`}
			style={
				isDesktop
					? offsetDesktop
					: isTablet
					? offsetTablet
					: isMobile
					? offsetMobile
					: null
			}
			onClick={onClick}
		>
			<div
				className={`
						${arrowClassName}
						${light === true ? styles.light : styles.dark}
						
					`}
			></div>
		</button>
	);
};

export default SlickPrevArrow;
