import { IVideo } from "interfaces/index";
import styles from "./Video.module.scss";

interface VideoProps {
	video: IVideo;
}

const Video = ({ video }: VideoProps): JSX.Element => {
	return (
		<div className={styles.videoContainer}>
			<div className={styles.holder}></div>
			<div className="ratio ratio-16x9">
				{video?.fields?.platform === "youtube" ? (
					<iframe
						src={`https://www.youtube.com/embed/${video?.fields?.videoId}`}
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen={true}
					></iframe>
				) : (
					<iframe
						src={`https://player.vimeo.com/video/${video?.fields?.videoId}`}
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen={true}
					></iframe>
				)}
			</div>
		</div>
	);
};

export default Video;
